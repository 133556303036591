
window.an_sn                  = window.an_sn || {};
window.an_sn.queue            = window.an_sn.queue || [];
window.an_sn.__sn             = null;
var __firstQueueProcessing    = true;

window.an_sn.init = function (data, purpose = 'all'){
    
    var naMode = 0;

    if (data['mode_A'] == undefined || (data['mode_A'] !== undefined && data['mode_A'] === 2)) {
        naMode = 1;
    } 
    
    if(window.senp && data.hasOwnProperty('an_sn_e') && data.an_sn_e){
        
        var pageLevelDefinition = null;
        if(data['pageLevelDefinition'] !== undefined){
            
            pageLevelDefinition = data['pageLevelDefinition'];
        }
        
        var infiniteScrollEvent = null;
        if(data['infiniteScrollEvent'] !== undefined){

            infiniteScrollEvent = data['infiniteScrollEvent'];
        }

        window.an_sn.__sn = new senp('senp.png', 'article', naMode, pageLevelDefinition, infiniteScrollEvent, purpose);
    }

}

window.an_sn.setMarkers = function (data){

    var naMode = 0;

    if (data['mode_A'] == undefined || (data['mode_A'] !== undefined && data['mode_A'] === 2)) {
        naMode = 1;
    } 
    
    if(window.senp && data.hasOwnProperty('an_sn_e') && data.an_sn_e){
        
        var pageLevelDefinition = null;
        if(data['pageLevelDefinition'] !== undefined){
            
            pageLevelDefinition = data['pageLevelDefinition'];
        }
        
        var infiniteScrollEvent = null;
        if(data['infiniteScrollEvent'] !== undefined){

            infiniteScrollEvent = data['infiniteScrollEvent'];
        }

        

       new senp('senp.png', 'article', naMode, pageLevelDefinition, infiniteScrollEvent, 'markers');
    }
    

}

window.an_sn.processQueue = function (){

    // Execute all closures currently in the queue.
    while(window.an_sn.queue.length > 0){

        var closure = window.an_sn.queue.shift();
        
        try{

            closure();
        }
        catch(error){

            console.error('Error encountered while processing queue.', {
                error: error,
                closure: closure,
            });
        }
    }

    if(__firstQueueProcessing){

        // Override methods to call our handler.
        // This way closures pushed to the queue will be executed immediately from this point on.
        window.an_sn.queue.push = function(){

            Array.prototype.push.apply(this, arguments);
            window.an_sn.processQueue();
        };

        window.an_sn.queue.unshift = function(){

            Array.prototype.unshift.apply(this, arguments);
            window.an_sn.processQueue();
        };

        __firstQueueProcessing = false;
    }
}

if('complete' !== document.readyState){

    document.addEventListener('readystatechange', function(event){

        if('complete' === event.target.readyState){

            window.an_sn.processQueue();
        }
    });
}
else{

    window.an_sn.processQueue();
}